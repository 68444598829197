import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"

import { Button, Number } from "component"
import Sfx from "component/Sfx"

const Header = () => {
  const dispatch = useDispatch()
  const { tr, info } = useSelector((state) => state.main)
  const { player } = info

  const handleClickButton = (type) => {
    dispatch(toggleModal({ name: type, state: true }))
  }

  return (
    <header>
      <div className="left">
        <Sfx />
        <div className="uid">
          {tr.user_id} <span>{player.account_id}</span>
        </div>
        <Button
          className="ico-history"
          onClick={() => handleClickButton("history")}
        />
        <Button
          className="ico-info"
          onClick={() => handleClickButton("info")}
        />
      </div>
      <div className="center">
        <img className="logo-ff" src={tr.img_logo_ff} alt="" />
        <h1 className="title">{tr.txt_title}</h1>
      </div>
      <div className="right">
        <div className="voucher">
          <img className="ico-voucher" src={tr.img_ico_voucher} alt="" />
          <span>
            <Number end={player.vouchers} />
          </span>
        </div>
      </div>
    </header>
  )
}

export default Header
