import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"

import storage from "redux-persist/lib/storage"

import auth from "./auth"
import main from "./main"
import modal from "./modal"
import throttle from "./throttle"

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [""],
}

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["err_code"],
}

const throttlePersistConfig = {
  key: "throttle",
  storage: storage,
  blacklist: [""],
}

const mainPersistConfig = {
  key: "main",
  storage: storage,
  // Ex: whitelist: ["login", "history"]
  // Only login and history will be persisted
  whitelist: ["sfx"],
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  main: persistReducer(mainPersistConfig, main),
  throttle: persistReducer(throttlePersistConfig, throttle),
  modal,
})

export default persistReducer(rootPersistConfig, rootReducer)
