import React from "react"

import { useSelector } from "react-redux"

import Error from "./Error"
import Info from "./Info"
import History from "./History"
import Confirm from "./Confirm"
import Thankyou from "./Thankyou"
import Congrat from "./Congrat"

const ModalCont = () => {
  const { info } = useSelector((state) => state.main)

  if (!info) {
    return <Error />
  }

  return (
    <>
      <Error />
      <History />
      <Info />
      <Confirm />
      <Thankyou />
      <Congrat />
    </>
  )
}

export default ModalCont
