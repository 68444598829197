import axios from "axios"
import { err_msg } from "helper"
import { toggleModal, toggleToast } from "./modal"

const common = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
  withCredentials: false,
})

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
})

export const toggle_loader = (data) => ({
  type: "TOGGLE_LOADER",
  data: data,
})

export const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
})

export const get_translate = (default_lang = "IND") => {
  return (dispatch) => {
    let language = default_lang

    const main_localize = cdn.get(`${language}.json?${Math.random() * 10000}`)
    const common_localize = common.get(
      `${language}.json?${Math.floor(Math.random() * 10000)}`
    )

    axios
      .all([main_localize, common_localize])
      .then((resp) => {
        const json = { ...resp[0].data, ...resp[1].data }
        dispatch(put_data("tr", json))
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)))
      })
  }
}

export const get_info = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true))

    axios
      .get("api/info")
      .then((resp) => {
        if (resp.data.status === "error") throw new Error()
        dispatch(put_data("info", resp.data.data))
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)))
      })
      .then(() => {
        dispatch(toggle_loader(false))
      })
  }
}

export const get_history = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true))

    axios
      .get("api/history")
      .then((resp) => {
        if (resp.data.status === "error") throw new Error()
        dispatch(put_data("histories", resp.data.data.history))
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)))
      })
      .then(() => {
        dispatch(toggle_loader(false))
      })
  }
}

export const post_dr_voucher = (voucher) => {
  return (dispatch, getState) => {
    const { info, tr } = getState().main;
    const minimumVoucher = info?.common_config?.min_voucher_buy_count;

    if (voucher < minimumVoucher) {
      dispatch(catch_error(tr?.[`error_min_voucher`]?.replace('{count}', minimumVoucher)));
      return;
    }

    dispatch(toggle_loader(true))

    axios
      .post("api/donate", { voucher: Number(voucher) })
      .then((resp) => {
        if (resp.data.status === "error") throw new Error()
        dispatch(
          toggleModal({
            name: "thankyou",
            state: true,
            data: { type: "voucher", amount: voucher },
          })
        )
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)))
      })
      .then(() => {
        dispatch(toggle_loader(false))
        dispatch(toggleModal({ name: "confirm", state: false }))
      })
  }
}

export const post_milestone = (stage, is_personal = false) => {
  return (dispatch) => {
    dispatch(toggle_loader(true))

    axios
      .post("api/milestone", { stage, is_personal })
      .then((resp) => {
        if (resp.data.status === "error") throw new Error()
        dispatch(
          toggleModal({
            name: "congrat",
            state: true,
            data: resp.data.data,
          })
        )
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)))
      })
      .then(() => {
        dispatch(toggle_loader(false))
      })
  }
}

export const catch_error = (errMsg) => {
  return (dispatch) => {
    dispatch(toggleToast({ state: true, msg: errMsg }))
    dispatch(toggle_loader(false))

    if (err_msg === "invalid_region") {
      localStorage.clear()
    }
  }
}
