import React, { useEffect } from "react"

import { format, fromUnixTime } from "date-fns"
import { useDispatch, useSelector } from "react-redux"

import { get_history } from "redux/actions/main"
import { formatQty } from "helper"
import BaseModal from "helper/component/basemodal"
import { audio } from "helper/audio"

const History = () => {
  const dispatch = useDispatch()
  const { tr, histories } = useSelector((state) => state.main)
  const { name } = useSelector((state) => state.modal)

  const textType = {
    personal_milestone: tr.type_personal_milestone,
    donate: tr.type_donate,
    global_milestone: tr.type_global_milestone,
  }

  useEffect(() => {
    if (!name.history) return

    audio("popup").play()
    dispatch(get_history())
  }, [dispatch, name.history])

  return (
    <BaseModal tag="history" title={tr.title_history}>
      <div className="thead">
        <div className="date">{tr.txt_date}</div>
        <div className="type">{tr.txt_type}</div>
        <div className="item">{tr.txt_items}</div>
      </div>
      <div className="tbody">
        {histories.map((history, i) => (
          <div key={i} className="list">
            <div className="date">
              {format(
                fromUnixTime(history.created_at),
                "dd'.'MM'.'yyyy HH':'mm"
              )}
            </div>
            <div
              className={`type ${
                history.type !== "donate" ? " highlight" : ""
              }`}
            >
              {textType[history.type] || ""}
            </div>
            <div
              className={`item ${
                history.type !== "donate" ? " highlight" : ""
              }`}
            >
              {history.item_name} {formatQty(history.item_num)}
            </div>
          </div>
        ))}
      </div>
    </BaseModal>
  )
}

export default History
