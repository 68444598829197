import ReactGA from "react-ga4";
import axios, { CancelToken } from "axios";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


export const setupLayout = () => {
    var resetTimer;
    // force single page application
    const setRootFontSize = () => {
        // designSafeArea = 1334px * 660px (landscape NOT include browser nav 90px)
        let designSafeWidth = 1334;
        let designSafeHeight = 660;
        let vmax = Math.max(window.innerHeight, window.innerWidth);
        let vmin = Math.min(window.innerHeight, window.innerWidth);
        let vmaxToFontSize = (vmax / designSafeWidth) * 100;
        let vminToFontSize = (vmin / designSafeHeight) * 100;
        let fontSize = Math.min(vmaxToFontSize, vminToFontSize, 100);

        // set root font size and html viewport
        const elem = document.documentElement;
        elem.style.fontSize = `${fontSize}px`;
        elem.style.height = `${vmin}px`;
        elem.style.width = `${vmax}px`;
    }

    const resetRootFontSize = () => {
        if (resetTimer) {
            clearTimeout(resetTimer);
        }
        resetTimer = setTimeout(function () {
            setRootFontSize();
        }, 200);
    }
    setRootFontSize();
    window.addEventListener('resize', resetRootFontSize);    
};

export const setAnalytics = () => {
	ReactGA.initialize([
		{
			trackingId: process.env.REACT_APP_GA
		}
	]);
	ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
	ReactGA.event({
		category: window.location.origin + window.location.pathname,
		action: window.releases
	});
};

export const setAxios = () => {
	// SET DEFAULT AXIOS
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

	const errorMsg = {
		type: "error",
		code: "TOAST_NETWORK_ERROR" // DUPLICATE|TIMEOUT|other key
	};

	const requestUrls = [];
	let requestFlag = "";
	const removeRequestUrl = () => {
		// remove the request from the queue
		requestUrls.splice(requestUrls.indexOf(requestFlag), 1);
	};
	const regex = /.*csrftoken=([^;.]*).*$/; // Used to match csrftoken value from cookie

	if (process.env.REACT_APP_USE_TOKEN === "true") {
		let token = window.localStorage.getItem("token");
		axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
	}

	// SET AXIOS INTERCEPTOR REQUEST
	axios.interceptors.request.use(
		function (config) {
			requestFlag = config.url + config.method;
			if (requestUrls.indexOf(requestFlag) > -1) {
				config.cancelToken = new CancelToken((cancel) => {
					errorMsg.code = "DUPLICATE";
					cancel("duplicate request");
				});
			} else {
				requestUrls.push(requestFlag);
				if(document.cookie.match(regex)){
					config.headers["X-CSRFToken"] = document.cookie.match(regex) ? document.cookie.match(regex)[1] : null;				
				}
			}
			return config;
		},
		(error) => Promise.reject(error)
	);

	// SET AXIOS INTERCEPTOR RESPONSE
	axios.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			removeRequestUrl();
			if (response.data.status === "error") {
				// const transify = store.getState().main.transify;
				errorMsg.code = response.data.msg;
				// if (transify[errorMsg.code]) {
				//     errorMsg.code = transify[errorMsg.code];
				// }

				// store.dispatch(
				// 	toggleToast({
				// 		state: true,
				// 		msg: errorMsg.code
				// 	})
				// );
				return Promise.reject(errorMsg);
			}
			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			
			removeRequestUrl();
			// Add Duplicate Error;
			if (error.message && error.message === "duplicate request") {
				errorMsg.code = "DUPLICATE";
				return Promise.reject(errorMsg);
			}

			// Add Timeout Error;
			if (error.message && error.message.indexOf("timeout") > -1) {
				errorMsg.code = "TOAST_SERVER_TIMEOUT";
				// store.dispatch(
				//     toggleToast({
				//         state: true,
				//         msg: errorMsg.code,
				//     })
				// )
				return Promise.reject(errorMsg);
			}

			// if (error.response && error.response.data.msg) {
			//     errorMsg.code = error.response.data.msg
			// }
			// store.dispatch(
			//     toggleToast({
			//         state: true,
			//         msg: errorMsg.code,
			//     })
			// )

			return Promise.reject(error);
		}
	);
};

export const setSentry = () => {
	Sentry.setContext("additional", {
		localStorage: window.localStorage,
		loginUrl: window.location.href
	});
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		environment: process.env.NODE_ENV,
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0
	});
};
