import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { catch_error } from "redux/actions/main"
import { toggleModal } from "redux/actions/modal"

import { Button } from "component"

const Donate = () => {
  const dispatch = useDispatch()
  const { tr, info } = useSelector((state) => state.main)
  const { common_config, player } = info
  const minVoucher = common_config?.min_voucher_buy_count

  const handleClickButton = (type) => {
    if (type === "buy" && player.diamonds < common_config.voucher_price) {
      dispatch(catch_error("NOT_ENOUGH_GEMS"))
      return
    }
    if (type === "donate" && player?.vouchers < minVoucher) {
      dispatch(catch_error(tr?.[`error_min_voucher`]?.replace('{count}', minVoucher)))
      return
    }
    dispatch(toggleModal({ name: "confirm", state: true, data: type }))
  }

  return (
    <section className="donate">
      <div className="frame">
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: tr.headline_center }}
        ></div>
        <Button
          className={`btn-donate ${player.vouchers ? "" : "disabled"}`}
          onClick={() => handleClickButton("donate")}
        >
          {tr.txt_btn_donate}
        </Button>
      </div>
    </section>
  )
}

export default Donate
