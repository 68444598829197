import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import Modal from "react-responsive-modal"

import { toggleToast } from "redux/actions/modal"
import { audio } from "helper/audio"

const Error = () => {
  const dispatch = useDispatch()
  const { tr } = useSelector((state) => state.main)
  const { toast, msg } = useSelector((state) => state.modal)

  const closeToast = () => {
    if (isClosable) {
      dispatch(toggleToast({ state: false }))
    }
  }

  const generateMsg = (errCode) => {
    switch (errCode) {
      case "NOT_ENOUGH_GEMS":
        return tr.error_not_enough_gems
      case "NOT_ENOUGH_VOUCHERS":
        return tr.error_not_enough_vouchers
      case "MILESTONE_ALREADY_CLAIMED":
        return tr.error_milestone_already_claimed
      default:
        return errCode
    }
  }

  const errCode = {
    NOT_LOGIN: "Not Login",
    TOAST_EVENTOVER: "Event ended",
    TOAST_EVENT_CLOSED_AWHILE: "The event is closed temporary",
    TOAST_EVENT_END: "Event ended",
    TOAST_EVENT_NOTOPEN: "Event hasn't started",
    TOAST_LOGIN_FAILED: "Login failed",
    TOAST_WRONG_REGION: "This event is not available for your region",
    VISITOR_NOT_ALLOWED: "Visitor Not Allowed",
    TOAST_SERVER_BUSY: "Server busy, please try again later",
    TOAST_SERVER_TIMEOUT: "Server Timeout",
    TOAST_SERVER_NOTWORK: "TOAST_SERVER_NOTWORK",
    MAINTENANCE: "Maintenance",
  }

  const isOpen = toast
  const isClosable = !errCode[msg]

  useEffect(() => {
    if (!toast) return
    audio("toast").play()

    const timerFunc = setTimeout(() => {
      closeToast()
    }, 2000)

    return () => clearTimeout(timerFunc)
    // eslint-disable-next-line
  }, [toast])

  return (
    <Modal
      open={isOpen}
      onClose={closeToast}
      closeOnOverlayClick={isClosable ? true : true}
      center
      showCloseIcon={false}
      closeOnEsc={false}
      focusTrapped={false}
      classNames={{
        overlay: "modal__overlay overlay-toast",
        modal: `modal toast`,
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "fadeOut",
      }}
      animationDuration={250}
    >
      <div className="modal__container toast" onClick={closeToast}>
        <div className="modal__content toast">
          <p>{errCode[msg] || generateMsg(msg)}</p>
        </div>
      </div>
    </Modal>
  )
}

export default Error
