const main_state = {
  loader: false,
  tr: false,
  assets: false,
  info: false,
  leaderboards: [],
  histories: [],
  sfx: true,
}

const main = (state = main_state, action) => {
  switch (action.type) {
    case "PUT_DATA":
      return { ...state, [action.key]: action.data }
    case "TOGGLE_LOADER":
      return { ...state, loader: action.data }
    default:
      return state
  }
}

export default main
