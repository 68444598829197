import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import BaseModal from "helper/component/basemodal"
import { post_dr_voucher } from "redux/actions/main"
import { Button } from "component"
import { formatNumericNumber, throttle } from "helper"
import { audio } from "helper/audio"

const Confirm = () => {
  const dispatch = useDispatch()
  const { tr, info } = useSelector((state) => state.main)
  const { name } = useSelector((state) => state.modal)
  const { player } = info
  const maxVoucherConfig = info?.common_config?.max_voucher_buy_count
  
  const minVoucher = info?.common_config?.min_voucher_buy_count
  const maxVoucher = player?.vouchers < maxVoucherConfig ? player.vouchers : maxVoucherConfig

  const [valueSlider, setValueSlider] = useState(minVoucher)

  const handleClickButton = () => {
    dispatch(post_dr_voucher(valueSlider))
  }

  const handleChangeValue = (e) => {
    setValueSlider(e.target.value)
  }

  useEffect(() => {
    if (!name.confirm) return
    audio("popup").play()
    setValueSlider(minVoucher)
  }, [name.confirm, minVoucher])

  return (
    <BaseModal tag="confirm" title={tr.title_popup_donate}>
      <div className="header">
        <p>{tr.popup_how_much_donate}</p>
        <div className="amount">
          <img className="ico-voucher" src={tr.img_ico_voucher} alt="" />
          <span>{formatNumericNumber(player.vouchers)}</span>
        </div>
      </div>
      <div className="value">{formatNumericNumber(valueSlider)}</div>
      <div className="slider">
        <span>MIN</span>
        <input
          type="range"
          min={minVoucher}
          max={maxVoucher}
          value={valueSlider}
          onChange={(e) => handleChangeValue(e)}
        />
        <span>MAX</span>
        <div className="ico-voucher" />
      </div>
      <Button
        onClick={() => throttle("donatebuy", () => handleClickButton(), 1000)}
      >
        {tr.type_donate}
      </Button>
    </BaseModal>
  )
}

export default Confirm
