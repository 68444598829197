import React, { useEffect } from "react"

import { useSelector } from "react-redux"

import BaseModal from "helper/component/basemodal"
import Support from "helper/component/support"
import { audio } from "helper/audio"

const Info = () => {
  const { tr } = useSelector((state) => state.main)
  const { name } = useSelector((state) => state.modal)

  useEffect(() => {
    if (!name.info) return

    audio("popup").play()
  }, [name.info])

  return (
    <BaseModal tag="info" title={tr.title_info}>
      <div
        className="event-info"
        dangerouslySetInnerHTML={{ __html: tr.detail_event_info }}
      />
      <div className="support-wrapper">
        <Support
          backgroundColor={tr.color_btn_support}
          textColor={tr.color_text_btn_support}
        />
      </div>
    </BaseModal>
  )
}

export default Info
