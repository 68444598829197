import React, { useRef } from "react"

import Tippy from "@tippyjs/react"
import { audio } from "helper/audio"

const Tooltip = ({
  content,
  disabled,
  children,
  placement = "top",
  timer = 2000,
}) => {
  const timerRef = useRef()

  return (
    <Tippy
      zIndex={9999}
      className="tooltip"
      content={content}
      disabled={disabled}
      placement={placement}
      offset={[0, 0]}
      trigger="click"
      hideOnClick={true}
      onShow={(instance) => {
        if (!timer) return
        audio("click").play()
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          instance.hide()
        }, timer)
      }}
      onHide={() => {
        clearTimeout(timerRef.current)
      }}
    >
      {children}
    </Tippy>
  )
}

export default Tooltip
