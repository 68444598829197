import React from "react"
import Modal from "react-responsive-modal"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"
import { audio } from "helper/audio"

function BaseModal({
  title,
  children,
  tag,
  size = "big",
  onExit = () => {},
  showCloseIcon = true,
  ...props
}) {
  const onClose = () => {
    audio("close").play()
    onExit()
    dispatch(toggleModal({ name: tag }))
  }

  const dispatch = useDispatch()
  const isOpen = useSelector((state) => state.modal.name[tag])

  return (
    <Modal
      container={document.getElementById("Main")}
      open={isOpen}
      onClose={onClose}
      center={true}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScroll={false}
      classNames={{
        modal: `modal modal-${tag} popup-${size}`,
        overlay: `modal__overlay overlay-${tag}`,
        closeButton: `btn-close close-${tag}`,
        modalAnimationIn: "fadeIn",
        modalAnimationOut: "fadeOut",
      }}
      closeIcon={<div className="ico-close" />}
      showCloseIcon={showCloseIcon}
      focusTrapped={false}
      animationDuration={400}
      {...props}
    >
      <div
        className={`modal__container ${tag}`}
        style={{ animationDelay: 300 }}
      >
        {title && (
          <div className={`modal__title ${tag}`}>
            <div className={`title-${tag}`}>{title}</div>
          </div>
        )}
        <div className={`modal__content ${tag}`}>{children}</div>
      </div>
    </Modal>
  )
}

export default BaseModal
