const initialState = {
  msg: '',
  closeIcon: true,
  toast: false,
  type: 'error',
  name: {},
  data: false,
};

const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        name: {
          ...state.name,
          [payload.name]: payload.state,
        },
        data: payload.data ? payload.data : state.data,
      };
    case 'TOGGLE_TOAST':
      return {
        ...state,
        msg: payload.msg,
        toast: payload.state,
        closeIcon: payload.closeIcon,
        type: 'error',
      };
    default:
      return state;
  }
};

export default modal;
