import React from "react"

import CountUp from "react-countup"

const Number = (props) => {
  const { end } = props

  return <CountUp end={end} duration={0.5} separator="." preserveValue />
}

export default Number
