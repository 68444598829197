import { preload } from "helper";

function generateCSSVariable(localize) {
  const data = Object.entries(localize).filter(([key]) => key.startsWith('img_') || key.startsWith('color_'));
  const target = document.documentElement;
  const preloadData = [];

  data.forEach(([key, value]) => {
    if (key.startsWith('img_') && value) preloadData.push(value);
    setCSSVariable({
      target,
      key,
      value,
    });
  });
  preload(preloadData);
}

function replaceKeytoVar(key) {
  return key?.replace('img_', '--img-')?.replace('color_', '--color-')?.replace(/_/g, '-');
}

function setCSSVariable({ target, key, value }) {
  if (!target) target = document.documentElement;
  target?.style.setProperty(replaceKeytoVar(key), String(key).startsWith('img_') ? `url(${value})` : value);
}

export default generateCSSVariable;
