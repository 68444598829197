import axios from "axios"
import { catch_error, get_translate, get_info } from "./main"
import { err_msg, getAllUrlParam, setAttributes } from "helper"

const ls = window.localStorage

export const set_param = (data) => ({
  type: "SET_PARAM",
  data,
})

export const set_token = () => {
  return (dispatch, getState) => {
    const { auth } = getState()
    const params = getAllUrlParam()

    let param = Object.keys(params).length === 0 ? auth?.param : params
    dispatch(get_translate())

    if (param?.access_token || param?.gop_access_token) {
      dispatch(getJWT(param))
        .then(() => {
          dispatch(set_param(param))
          dispatch(get_info(true))
        })
        .catch((err) => {
          dispatch(catch_error(err_msg(err)))
        })
    } else {
      dispatch(catch_error("NOT_LOGIN"))
    }

    setAttributes(param.lang)
  }
}

export const getJWT = (param) => {
  return async () => {
    return axios.get(`oauth/callback/`, { params: param }).then((resp) => {
      ls.setItem("token", resp.data.token)
      axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] =
        resp.data.token
    })
  }
}
