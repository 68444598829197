import { Howl } from "howler"
import { CDN } from "helper"

export const audio = (type, option) => {
  const sounds = {
    click: {
      mpeg: CDN + "sounds/click.mp3",
      wav: CDN + "sounds/click.wav",
    },
    confirm: {
      mpeg: CDN + "sounds/confirm.mp3",
      wav: CDN + "sounds/confirm.wav",
    },
    close: {
      mpeg: CDN + "sounds/close.mp3",
      wav: CDN + "sounds/close.wav",
    },
    popup: {
      mpeg: CDN + "sounds/popup.mp3",
      wav: CDN + "sounds/popup.wav",
    },
    popupNormal: {
      mpeg: CDN + "sounds/popup_normal.mp3",
      wav: CDN + "sounds/popup_normal.wav",
    },
    toast: {
      mpeg: CDN + "sounds/toast.mp3",
      wav: CDN + "sounds/toast.wav",
    },
  }

  const sound = new Howl({
    src: [sounds[type].mpeg, sounds[type].wav],
    ...option,
  })

  return sound
}
