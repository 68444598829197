import React, { useEffect, lazy, Suspense } from "react"
import { Routes, BrowserRouter, Route } from "react-router-dom"
import { ErrorBoundary } from "@sentry/react"

import { useDispatch, useSelector } from "react-redux"
import { set_token } from "redux/actions/auth"

// Component
import ModalCont from "component/Modal"
import ViewportRestrict from "helper/component/viewport"
import Loader from "helper/component/loader"
import NotFound from "helper/component/404"
import LoadingScreen from "helper/component/loadingscreen"
import Fallback from "helper/component/fallback"

import { componentLoader } from "helper"
import generateCSSVariable from "helper/css"

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")))

export const Main = () => {
  const dispatch = useDispatch()
  const { loader, tr } = useSelector((state) => state?.main)

  useEffect(() => {
    dispatch(set_token())
  }, [dispatch])

  useEffect(() => {
    tr && generateCSSVariable(tr)
  }, [tr])

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div id="Main" className="main-panel">
        <ErrorBoundary fallback={Fallback}>
          <Suspense fallback={<LoadingScreen />}>
            <ViewportRestrict display={true} type="portrait" />
            <Loader loader={loader} />
            <ModalCont />
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  )
}

export default Main
