import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { get_info } from "redux/actions/main"
import { toggleModal } from "redux/actions/modal"

import { Button } from "component"
import { throttle } from "helper"
import BaseModal from "helper/component/basemodal"
import { audio } from "helper/audio"

const Thankyou = () => {
  const dispatch = useDispatch()
  const { tr } = useSelector((state) => state.main)
  const { name, data } = useSelector((state) => state.modal)

  const handleExit = () => {
    audio("confirm").play()
    dispatch(toggleModal({ name: "thankyou", state: false }))
    dispatch(get_info())
  }

  useEffect(() => {
    if (!name.thankyou) return

    audio("popupNormal").play()
  }, [name.thankyou])

  return (
    <BaseModal
      tag="thankyou"
      title={tr.title_popup_thankyou}
      showCloseIcon={false}
    >
      <p>{tr.popup_after_donate_1}</p>
      <span>
        <img className="ico-voucher" src={tr.img_ico_voucher} alt="" />{" "}
        {data.amount}
      </span>
      <p>{tr.popup_after_donate_2}</p>
      <Button onClick={() => throttle("thankyou", () => handleExit(), 1000)}>
        Oke
      </Button>
    </BaseModal>
  )
}

export default Thankyou
