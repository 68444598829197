import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { get_info } from "redux/actions/main"
import { toggleModal } from "redux/actions/modal"

import { Button } from "component"
import { formatQty, throttle } from "helper"
import BaseModal from "helper/component/basemodal"
import { audio } from "helper/audio"

const Congrat = () => {
  const dispatch = useDispatch()
  const { tr } = useSelector((state) => state.main)
  const { name, data } = useSelector((state) => state.modal)
  const { rewards: prize, token_statuses } = data

  const handleExit = () => {
    audio("confirm").play()
    dispatch(toggleModal({ name: "congrat", state: false }))
    dispatch(get_info())
  }

  useEffect(() => {
    if (!name.congrat) return
    audio("popupNormal").play()
  }, [name.congrat])

  return (
    <BaseModal
      tag="congrat"
      title={tr.title_popup_congrats}
      onExit={handleExit}
    >
      <p>{tr.popup_got_prize}</p>
      <div className="img-wrapper">
        <img src={prize?.item_img} alt="" />
      </div>
      <p>
        {prize?.item_name} {formatQty(prize?.item_num)}
      </p>
      {String(token_statuses).indexOf(1) !== -1 && (
        <span className="convert-item">{tr.txt_convert_item}</span>
      )}
      <Button onClick={() => throttle("congrat", () => handleExit(), 1000)}>
        Oke
      </Button>
    </BaseModal>
  )
}

export default Congrat
