import React from "react"

import { useSelector } from "react-redux"

const Button = ({ className = "", style, onClick = () => {}, children }) => {
  const { on_animation } = useSelector((state) => state.main)

  return (
    <button
      className={className}
      style={style}
      onClick={() => {
        if (!on_animation) {
          onClick()
        } else {
          return undefined
        }
      }}
    >
      {children}
    </button>
  )
}

export default Button
