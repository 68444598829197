import React, { useState, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { Howler, Howl } from "howler"

import { Button } from "component"
import { put_data } from "redux/actions/main"
import { game } from "helper/game"

const Sfx = () => {
  const dispatch = useDispatch()
  const { tr, sfx } = useSelector((state) => state.main)
  const [ingameVolume, setIngameVolume] = useState(1)

  const BGM = () =>
    new Howl({
      src: [tr.sound_bgm],
      ...{ loop: true },
    })

  const onClickBtnSfx = () => {
    if (ingameVolume === "0") {
      BGM().play()
      Howler.volume(1)
    } else {
      Howler.volume(ingameVolume)
    }
    Howler.mute(sfx)
    dispatch(put_data("sfx", !sfx))
  }

  useEffect(() => {
    if (!sfx) {
      Howler.mute(true)
      dispatch(put_data("sfx", false))
    } else {
      if (game.inGame()) {
        game.run("Volume", "music").then((res) => {
          setIngameVolume(res)
          if (res === "0") {
            Howler.mute(true)
            dispatch(put_data("sfx", false))
          } else {
            Howler.volume(res)
          }
        })
      }
    }
    BGM().play()
  }, [tr, dispatch]) // eslint-disable-line

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        Howler.mute(!sfx)
      } else {
        Howler.mute(true)
      }
    })
  }, [sfx])

  return (
    <div className="sfx-container" onClick={onClickBtnSfx}>
      <Button
        className={`ico-sound${!sfx ? " off" : ""}`}
        style={{ marginRight: 0 }}
      />
    </div>
  )
}

export default Sfx
